.img-grid {
  margin: 20px auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 40px;

  max-width: 70vw;
  /*margin: 0 auto;*/
}

.img-wrap {
  overflow: hidden;
  height: 0;
  padding: 50% 0;
  position: relative;
  opacity: 0.8;
}

.img-wrap img {
  min-width: 100%;
  min-height: 100%;
  max-width: 150%;
  position: absolute;
  top: 0;
  left: 0;
}

.grid-div {
  max-width: 70vw;
  margin: 0 auto;
  /*align-items: center;*/
}

.text {
  width: 100%;
  /*position: absolute;*/
  bottom: 0;
  left: 0;
  color: whitesmoke;
  background-color: gray;
  padding-left: 5px;
  font-size: 1.4em;
}

@media screen and (max-width: 576px) {
  .text {
    font-size: 1em;
  }

  .grid-div {
    max-width: 100vw;
  }
}