.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.backdrop-img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 180px auto 0px;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
  border: 3px solid white;
}

@media screen and (max-width: 576px) {
  .backdrop-img {
    max-width: 100vw;
    /*margin-top: 120px;*/
    align-items: center;
    border: 0;
  }
}